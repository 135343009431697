import React from "react"
import Categories from "../components/Categories"
import Layout from "../components/Layout"

import Showers from "../components/Showers"

export default function Home() {
  return (
    <Layout>
      <Categories />
      <Showers />
    </Layout>
  )
}

import React from "react"
import { BsArrowsFullscreen } from "@react-icons/all-files/bs/BsArrowsFullscreen"
import Modal from "react-bootstrap/Modal"
import { useState } from "react"


export default function Showers() {
  let [url, setUrl] = useState("/images/portfolio/gallery/g-skaterboy.jpg")

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <section id="shower" className="portfolio">
      <div className="row narrow section-intro with-bottom-sep">
        <div className="col-twelve">
          <h1 className="animate-this">Bathroom's</h1>
          <h3 className="animate-this">See My Latest Projects In Bathroom's.</h3>

          {/* <p className="lead animate-this">
            Lorem ipsum Dolor adipisicing nostrud et aute Excepteur amet commodo
            ea dolore irure esse Duis nulla sint fugiat cillum ullamco proident
            aliquip quis qui voluptate dolore veniam Ut laborum non est in
            officia.
          </p> */}
        </div>
      </div>

      <div className="row portfolio-content">
        <div className="col-twelve">
          <div id="folio-wrap" className="bricks-wrapper">
            {[3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17].map(el => (
              <div
                className="brick folio-item"
                onClick={() => {
                  setUrl(`/images/portfolio/showers/img-${el}.jpg`)

                  handleShow();
                }}
              >
                <div className="item-wrap animate-this" data-sub-html="#01">
                  <div className="overlay">
                    <span className="icon">
                      <BsArrowsFullscreen />
                    </span>
                    <div className="image">
                      <img
                        src={`/images/portfolio/showers/img-${el}.jpg`}
                        alt="Skaterboy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img className="image" src={url} alt="Skaterboy" />
        </Modal.Body>
      </Modal>
    </section>
  )
}
